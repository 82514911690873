

/* * * * * * * * * *

Checkbox Multi-Select for checkbox-list component

This will listen for clicks on checkboxes contained within an element with class=checkbox-multiselect and let
users select a range of checkboxes by shift-clicking. This triggers a click on the checkboxes in the range and
is not really integrated with Ember at all.

TODO: Maybe roll this into a plugin? Only use when needed?

*/
$(document).ready(function(){
	$(document).on('click', '.checkbox-multiselect input[type=checkbox]', function(e){

		var $multiselect = $(this).closest('.checkbox-multiselect');
		var lastChecked = $multiselect.data('lastChecked');

			if(!lastChecked) {
					lastChecked = this;
					$multiselect.data('lastChecked', this);
					return;
			}

			if(e.shiftKey) {

				// We need to get the parent multiselect container
			var $chkboxes = $multiselect.find('input[type=checkbox]');  

			var start = $chkboxes.index(this);
			var end = $chkboxes.index(lastChecked);

			// Checkboxes are checked if they exist in the 'source' array. We'll loop over the range and add/remove from the array.
			// The first and last checkboxes have just been manually clicked, so DO NOT trigger those.
			var collection = $chkboxes.slice(Math.min(start,end), Math.max(start,end)+ 1);

			var firstIndex = 0;
			var lastIndex = collection.length - 1;

			collection.each(function(index){
				if(index > firstIndex && index < lastIndex){
					$(this).click();
				}
			});
		}
		$multiselect.data('lastChecked', this);
	});
 
});